import { lazy } from 'react'
import { Navigate } from 'react-router'

import paths from '../paths'
import { withSuspense } from './routeUtils'

import { enquiryStatus, auctionStatus, strings } from '@constants'
import DashboardLayout from '@global/DashboardLayout'

import AdminEnquiry from '@pages/Admin/Enquiry'
import AdminAuction from '@pages/Admin/Auction'
import AdminTools from '@pages/Admin/Tools'
import AdminContent from '@pages/Admin/Content'
import AdminReports from '@pages/Admin/Reports'
import AdminUnderwriting from '@pages/Admin/Underwriting'
import DuePayments from '@pages/Admin/PaymentSummary/DuePayments'
import Collections from '@pages/Admin/PaymentSummary/Collections'
import LeadList from '@pages/Admin/OrgsAndUsers/Lead/LeadList'
import SaleCollections from '@pages/Collections'
import CategoryInfo from '@pages/Admin/Catalog/CreateCategory/CategoryInfo'
import OrgLeadList from '@pages/Admin/OrgsAndUsers/Organisation/OrgLeadList'
import FinancialList from '@pages/Admin/OrgsAndUsers/Organisation/Financial/FinancialList'
import OrderEstimateForm from '@pages/Admin/OrderEstimate/OrderEstimateForm'
import DataCorrection from '@pages/Admin/Tools/DataCorrection'
import Glossary from '@pages/Admin/Content/Glossary'
import AddGlossary from '@pages/Admin/Content/Glossary/AddGlossary'

const LeadOrganisationDetail = lazy(() => import('@pages/Admin/OrgsAndUsers/Organisation/OrganisationLeadStage'))
const AdminDashboard = lazy(() => import(/* webpackChunkName: "AdminDashboard" */ '@pages/Admin/Dashboard'))
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ '@pages/Profile'))

const OrgsAndUsers = lazy(() => import(/* webpackChunkName: "OrgsAndUsers" */ '@pages/Admin/OrgsAndUsers'))
const OrganisationsList = lazy(() => import(/* webpackChunkName: "OrganisationsList" */ '@pages/Admin/OrgsAndUsers/Organisation/OrganisationsList'))
const Organisation = lazy(() => import(/* webpackChunkName: "Organisation" */ '@pages/Admin/OrgsAndUsers/Organisation'))

export const OrganisationDetails = lazy(() =>
	import(/* webpackChunkName: "OrganisationDetails" */ '@pages/Admin/OrgsAndUsers/Organisation/OrganisationDetails')
)
export const OrganisationUsers = lazy(() =>
	import(/* webpackChunkName: "OrganisationUsers" */ '@pages/Admin/OrgsAndUsers/Organisation/OrganisationUsers')
)

export const OrganisationLedger = lazy(() =>
	import(/* webpackChunkName: "OrganisationLedger" */ '@pages/Admin/OrgsAndUsers/Organisation/OrganisationLedger')
)

export const OrganisationEditUser = lazy(() =>
	import(/* webpackChunkName: "OrganisationEditUser" */ '@pages/Admin/OrgsAndUsers/Organisation/OrganisationEditUser')
)

const AdminsList = lazy(() => import(/* webpackChunkName: "AdminsList" */ '@pages/Admin/OrgsAndUsers/AdminUsers/AdminsList'))
const PastRecords = lazy(() => import(/* webpackChunkName: "PastRecords" */ '@pages/Admin/PaymentSummary/PastRecords'))
const Unregistered = lazy(() => import(/* webpackChunkName: "Unregistered" */ '@pages/Admin/OrgsAndUsers/Unregistered'))
const UserList = lazy(() => import(/* webpackChunkName: "Unregistered" */ '@pages/Admin/OrgsAndUsers/UserList'))
const CatalogMain = lazy(() => import(/* webpackChunkName: "CatalogMain" */ '@pages/Admin/Catalog'))
const CategoryList = lazy(() => import(/* webpackChunkName: "CategoryList" */ '@pages/Admin/Catalog/CategoryList'))
const ProductList = lazy(() => import(/* webpackChunkName: "ProductList" */ '@pages/Admin/Catalog/ProductList'))
const BrandList = lazy(() => import(/* webpackChunkName: "BrandList" */ '@pages/Admin/Catalog/BrandList'))
const Commodity = lazy(() => import(/* webpackChunkName: "Commodity" */ '@pages/Admin/Catalog/Commodity'))
const AdminManageEnquiries = lazy(() => import(/* webpackChunkName: "AdminManageEnquiries" */ '@pages/Admin/Enquiry/ManageEnquiries'))
const AdminOrders = lazy(() => import(/* webpackChunkName: "AdminOrders" */ '@pages/Admin/Orders'))
const AdminOrdersByStatus = lazy(() => import(/* webpackChunkName: "AdminOrdersByStatus" */ '@pages/Admin/Orders/OrderByStatus'))
const AdminOrdersActiveInvoice = lazy(() => import(/* webpackChunkName: "AdminOrdersActiveInvoice" */ '@pages/Admin/Orders/ActiveInvoice'))
const AdminOrdersOrderBook = lazy(() => import(/* webpackChunkName: "AdminOrdersActiveInvoice" */ '@pages/Admin/Orders/OrderBook'))
const AdminOrdersActiveTrip = lazy(() => import(/* webpackChunkName: "AdminOrdersActiveTrip" */ '@pages/Admin/Orders/ActiveTrip'))
const AdminManageOrder = lazy(() => import(/* webpackChunkName: "AdminManageOrder" */ '@pages/Admin/Orders/ManageOrder'))
const AdminAuctionOrders = lazy(() => import(/* webpackChunkName: "AdminAuctionOrders" */ '@pages/Admin/AuctionOrders'))
const AdminAuctionOrdersByStatus = lazy(() => import(/* webpackChunkName: "AdminAuctionOrdersByStatus" */ '@pages/Admin/AuctionOrders/OrderByStatus'))
const AdminManageAuctionOrder = lazy(() => import(/* webpackChunkName: "AdminManageAuctionOrder" */ '@pages/Admin/AuctionOrders/ManageOrder'))
const AdminPaymentSummary = lazy(() => import(/* webpackChunkName: "AdminPaymentSummary" */ '@pages/Admin/PaymentSummary'))
const Ledgers = lazy(() => import(/* webpackChunkName: "Ledgers" */ '@pages/Admin/PaymentSummary/Ledgers'))
const SaleInvoices = lazy(() => import(/* webpackChunkName: "SaleInvoices" */ '@pages/Admin/PaymentSummary/SaleInvoices'))
const PurchaseInvoices = lazy(() => import(/* webpackChunkName: "PurchaseInvoices" */ '@pages/Admin/PaymentSummary/PurchaseInvoices'))
const Inventory = lazy(() => import(/* webpackChunkName: "Inventory" */ '@pages/Admin/Inventory/index'))

export const AdminCreateEnquiry = lazy(() => import(/* webpackChunkName: "AdminCreateEnquiry" */ '@pages/Admin/Enquiry/CreateEnquiry'))
const AdminAssignEnquiry = lazy(() => import(/* webpackChunkName: "AdminAssignEnquiry" */ '@pages/Admin/Enquiry/ManageEnquiries/AssignEnquiry'))
const AdminEditEnquiry = lazy(() => import(/* webpackChunkName: "AdminEditEnquiry" */ '@pages/Admin/Enquiry/ManageEnquiries/EditEnquiry'))
const AdminAllEnquiries = lazy(() => import(/* webpackChunkName: "AdminAllEnquiries" */ '@pages/Admin/Enquiry/ManageEnquiries/AllEnquiries'))
const AdminViewEnquiry = lazy(() => import(/* webpackChunkName: "AdminViewEnquiry" */ '@pages/Admin/Enquiry/ManageEnquiries/ViewEnquiry'))
const AdminVerifyEnquiry = lazy(() => import(/* webpackChunkName: "AdminVerifyEnquiry" */ '@pages/Admin/Enquiry/ManageEnquiries/VerifyEnquiry'))
const AdminActiveEnquiries = lazy(() => import(/* webpackChunkName: "AdminActiveEnquiries" */ '@pages/Admin/Enquiry/ManageEnquiries/ActiveEnquiries'))
const AdminMyEnquiries = lazy(() => import(/* webpackChunkName: "AdminMyEnquiries" */ '@pages/Admin/Enquiry/ManageEnquiries/MyEnquiries'))
const AdminManageEnquiry = lazy(() => import(/* webpackChunkName: "AdminManageEnquiry" */ '@pages/Admin/Enquiry/ManageEnquiries/ManageEnquiry'))

const AdminCreateAuction = lazy(() => import(/* webpackChunkName: "AdminCreateAuction" */ '@pages/Admin/Auction/CreateAuction'))
const AdminEditAuction = lazy(() => import(/* webpackChunkName: "AdminEditAuction" */ '@pages/Admin/Auction/ManageAuctions/EditAuction'))
const AdminViewAuction = lazy(() => import(/* webpackChunkName: "AdminViewAuction" */ '@pages/Admin/Auction/ManageAuctions/ViewAuction'))
const AdminManageAuction = lazy(() => import(/* webpackChunkName: "AdminManageAuction" */ '@pages/Admin/Auction/ManageAuctions/ManageAuction'))

const AdminManageAuctions = lazy(() => import(/* webpackChunkName: "AdminManageAuctions" */ '@pages/Admin/Auction/ManageAuctions'))
const AdminAssignAuction = lazy(() => import(/* webpackChunkName: "AdminAssignAuction" */ '@pages/Admin/Auction/ManageAuctions/AssignAuction'))
const AdminAllAuctions = lazy(() => import(/* webpackChunkName: "AdminAllAuctions" */ '@pages/Admin/Auction/ManageAuctions/AllAuctions'))
const AdminVerifyAuction = lazy(() => import(/* webpackChunkName: "AdminVerifyAuction" */ '@pages/Admin/Auction/ManageAuctions/VerifyAuction'))
const AdminActiveAuctions = lazy(() => import(/* webpackChunkName: "AdminActiveAuctions" */ '@pages/Admin/Auction/ManageAuctions/ActiveAuctions'))
const AdminMyAuctions = lazy(() => import(/* webpackChunkName: "AdminMyAuctions" */ '@pages/Admin/Auction/ManageAuctions/MyAuctions'))

const AdminLogistics = lazy(() => import(/* webpackChunkName: "AdminLogistics" */ '@pages/Admin/Logistics'))
const AddLogistics = lazy(() => import(/* webpackChunkName: "AddLogistics" */ '@pages/Admin/Logistics/CreateLogistics/AddLogistics'))
const EditLogistics = lazy(() => import(/* webpackChunkName: "EditLogistics" */ '@pages/Admin/Logistics/ManageLogistics/EditLogistics'))
const LogisticsAll = lazy(() => import(/* webpackChunkName: "LogisticsAll" */ '@pages/Admin/Logistics/ManageLogistics/LogisticsAll'))
const AdminManageLogistics = lazy(() => import(/* webpackChunkName: "AdminManageLogistics" */ '@pages/Admin/Logistics/ManageLogistics'))
const LogisticsOrder = lazy(() => import(/* webpackChunkName: "LogisticsOrder" */ '@pages/Admin/Logistics/ManageLogistics/LogisticsOrder'))
const LogisticsAllQuotes = lazy(() =>
	import(/* webpackChunkName: "LogisticsAllQuotes" */ '@pages/Admin/Logistics/ManageLogistics/LogisticsAllQuotes')
)
const LogisticsPrice = lazy(() => import(/* webpackChunkName: "LogisticsPrice" */ '@pages/Admin/Logistics/LogisticsPrice'))
const LogisticsOrderDetails = lazy(() =>
	import(/* webpackChunkName: "LogisticsOrderDetails" */ '@pages/Admin/Logistics/ManageLogistics/LogisticsOrderDetails')
)

const AdminCloudFactory = lazy(() => import(/* webpackChunkName: "AdminCloudFactory" */ '@pages/Admin/CloudFactory'))
const AdminListFactories = lazy(() => import(/* webpackChunkName: "AdminListFactories" */ '@pages/Admin/CloudFactory/ListFactories'))
const AdminListMachines = lazy(() => import(/* webpackChunkName: "AdminListMachines" */ '@pages/Admin/CloudFactory/ListMachines'))
const AdminCreateFactory = lazy(() => import(/* webpackChunkName: "AdminCreateFactory" */ '@pages/Admin/CloudFactory/CreateFactory'))
const AdminCreateMachine = lazy(() => import(/* webpackChunkName: "AdminCreateMachine" */ '@pages/Admin/CloudFactory/CreateMachine'))
const AdminEditMachine = lazy(() => import(/* webpackChunkName: "AdminEditMachine" */ '@pages/Admin/CloudFactory/EditMachine'))
const AdminEditFactory = lazy(() => import(/* webpackChunkName: "AdminEditFactory" */ '@pages/Admin/CloudFactory/EditFactory'))
const AdminListFactoryOrders = lazy(() => import(/* webpackChunkName: "AdminListFactoryOrders" */ '@pages/Admin/CloudFactory/Orders/ListOrders'))
export const AdminCreateFactoryOrder = lazy(() =>
	import(/* webpackChunkName: "AdminCreateFactoryOrder" */ '@pages/Admin/CloudFactory/Orders/CreateOrder')
)
const AdminManageCloudOrder = lazy(() => import(/* webpackChunkName: "AdminManageCloudOrder" */ '@pages/Admin/CloudFactory/Orders/ManageOrder'))

const AdminAllTools = lazy(() => import(/* webpackChunkName: "AdminAllTools" */ '@pages/Admin/Tools/AllTools'))
const AdminEndorsement = lazy(() => import(/* webpackChunkName: "AdminEndorsement" */ '@pages/Admin/Tools/TCEndorsement'))
const AdminInvoiceEndorsement = lazy(() => import(/* webpackChunkName: "AdminInvoiceEndorsement" */ '@pages/Admin/Tools/InvoiceEndorsement'))
const AdminLogisticCalculator = lazy(() => import(/* webpackChunkName: "AdminLogisticCalculator" */ '@pages/Admin/Tools/LogisticCalculator'))
const AdminDistanceCalculator = lazy(() => import(/* webpackChunkName: "AdminDistanceCalculator" */ '@pages/Admin/Tools/DistanceCalculator'))
const AdminVernacularize = lazy(() => import(/* webpackChunkName: "AdminVernacularize" */ '@pages/Admin/Tools/VernacularizeText'))
const AdminToolsIFC = lazy(() => import(/* webpackChunkName: "AdminToolsIFC" */ '@pages/Admin/Tools/AdminToolsIFC'))
const WeightCalculator = lazy(() => import(/* webpackChunkName: "WeightCalculator" */ '@pages/Admin/Tools/WeightCalculator'))

const AdminBanner = lazy(() => import(/* webpackChunkName: "AdminBanner" */ '@pages/Admin/Content/Banner'))
const AdminAllContent = lazy(() => import(/* webpackChunkName: "AdminAllContent" */ '@pages/Admin/Content/AllContents'))
const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ '@pages/Admin/Content/Blog'))
const CreateBlog = lazy(() => import(/* webpackChunkName: "CreateBlog" */ '@pages/Admin/Content/Blog/CreateBlog'))
const News = lazy(() => import(/* webpackChunkName: "News" */ '@pages/Admin/Content/News'))

const AdminAllReports = lazy(() => import(/* webpackChunkName: "AdminAllReports" */ '@pages/Admin/Reports/AllReports'))
const ViewReport = lazy(() => import(/* webpackChunkName: "ViewReport" */ '@pages/Admin/Reports/ViewReport'))

const CommodityPrices = lazy(() => import(/* webpackChunkName: "CommodityPrices" */ '@pages/Admin/CommodityPrices'))
const AdminInvoice = lazy(() => import(/* webpackChunkName: "AdminInvoice" */ '@pages/Admin/Invoice'))

const AllUnderwriting = lazy(() => import(/* webpackChunkName: "AllUnderwriting" */ '@pages/Admin/Underwriting/AllUnderwriting'))
const ViewUnderwritingReport = lazy(() => import(/* webpackChunkName: "ViewUnderwritingReport" */ '@pages/Admin/Underwriting/ViewReport'))
const UnderwritingReport = lazy(() => import(/* webpackChunkName: "UnderwritingReport" */ '@pages/Admin/Underwriting//AllUnderwriting/ReportList'))
const UnderwritingRequest = lazy(() => import(/* webpackChunkName: "UnderwritingRequest" */ '@pages/Admin/Underwriting/AllUnderwriting/RequestList'))
const MBDocuments = lazy(() => import(/* webpackChunkName: "MBDocuments" */ '@pages/Admin/MBDocuments'))
export const AdminOrderEstimate = lazy(() => import(/* webpackChunkName: "AdminOrderEstimate" */ '@pages/Admin/OrderEstimate'))

const AdminRoutes = {
	path: paths.admin,
	element: <DashboardLayout />,
	protected: true,
	children: [
		{ index: true, element: <Navigate to={paths.admin_dashboard} /> },
		{
			path: paths.routePath(paths.admin_dashboard, paths.admin),
			element: withSuspense(<AdminDashboard />),
			title: strings('dashboard'),
		},
		{ path: paths.routePath(paths.admin_profile, paths.admin), element: withSuspense(<Profile />), title: strings('my_profile') },
		{
			path: paths.routePath(paths.admin_orgs_and_users, paths.admin),
			element: withSuspense(<OrgsAndUsers />),
			title: strings('orgs_and_users'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_view_organisations} /> },
				{ path: paths.admin_view_organisations, element: withSuspense(<OrganisationsList />) },
				{ path: paths.admin_view_users, element: withSuspense(<UserList />) },
				{ path: paths.admin_lead_organisations, element: withSuspense(<LeadList />) },
				{
					path: paths.admin_lead_detail(),
					element: withSuspense(<LeadOrganisationDetail admin />),
				},
				{
					path: paths.admin_organisation(),
					element: withSuspense(<Organisation />),
					children: [
						{
							path: paths.admin_organisation_details(),
							element: withSuspense(<OrganisationDetails admin />),
						},
						{
							path: paths.admin_organisation_users(),
							element: withSuspense(<OrganisationUsers admin />),
						},
						{
							path: paths.admin_organisation_ledger(),
							element: withSuspense(<OrganisationLedger admin />),
						},
						{
							path: paths.admin_organisation_edit_user(),
							element: withSuspense(<OrganisationEditUser admin />),
						},
						{
							path: paths.admin_organisation_leadlist(),
							element: withSuspense(<OrgLeadList admin />),
						},
						{
							path: paths.admin_organisation_financials(),
							element: withSuspense(<FinancialList admin />),
						},
					],
				},
				{ path: paths.admin_view_admins, element: withSuspense(<AdminsList isAdmin />) },
				{ path: paths.admin_unregistered, element: withSuspense(<Unregistered isAdmin />) },
				{ path: paths.admin_edit_user(), element: withSuspense(<Profile isAdmin />) },
				{
					path: '*',
					element: <Navigate to={paths.admin_view_organisations} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_catalog, paths.admin),
			element: withSuspense(<CatalogMain />),
			title: strings('catalog'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_catalog_categories} /> },
				{
					path: paths.admin_catalog_categories,
					element: withSuspense(<CategoryList />),
				},
				{
					path: paths.admin_catalog_edit_category(),
					element: withSuspense(<CategoryInfo />),
				},
				{
					path: paths.admin_catalog_products,
					element: withSuspense(<ProductList />),
				},
				{
					path: paths.admin_catalog_brands,
					element: withSuspense(<BrandList />),
				},
				{
					path: paths.admin_catalog_commodity,
					element: withSuspense(<Commodity />),
				},
				{
					path: '*',
					element: <Navigate to={paths.admin_catalog_categories} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_enquiry, paths.admin),
			element: <AdminEnquiry />,
			children: [
				{ index: true, element: <Navigate to={paths.admin_create_enquiry} /> },
				{ path: paths.admin_create_enquiry, element: withSuspense(<AdminCreateEnquiry />), title: strings('create_enquiry') },
				{
					path: paths.admin_punch_order,
					element: withSuspense(<AdminOrderEstimate />),
					title: strings('punch_order'),
				},
				{ path: paths.admin_punch_order_create, element: withSuspense(<OrderEstimateForm />), title: strings(['create', 'punch_order']) },
				{ path: paths.admin_punch_order_view(), element: withSuspense(<OrderEstimateForm isView />), title: strings('punch_order') },
				{
					path: paths.admin_manage_enquiry,
					element: withSuspense(<AdminManageEnquiries />),
					title: strings('manage_enquiries'),
					children: [
						{ index: true, element: <Navigate to={paths.admin_manage_enquiry_active} /> },
						{ path: paths.admin_manage_enquiry_assign, element: withSuspense(<AdminAssignEnquiry status={enquiryStatus.created} />) },
						{ path: paths.admin_manage_enquiry_all, element: withSuspense(<AdminAllEnquiries />) },
						{ path: paths.admin_manage_enquiry_verify, element: withSuspense(<AdminVerifyEnquiry status={enquiryStatus.unverified} />) },
						{ path: paths.admin_manage_enquiry_active, element: withSuspense(<AdminActiveEnquiries status={enquiryStatus.active} />) },
						{ path: paths.admin_manage_enquiry_my, element: withSuspense(<AdminMyEnquiries />) },
						{ path: paths.admin_manage_enquiry_view(), element: withSuspense(<AdminViewEnquiry />) },
						{ path: paths.admin_manage_enquiry_edit(), element: withSuspense(<AdminEditEnquiry />) },
						{ path: paths.admin_manage_enquiry_manage(), element: withSuspense(<AdminManageEnquiry />) },
						{
							path: '*',
							element: <Navigate to={paths.admin_manage_enquiry_all} />,
						},
					],
				},
				{
					path: '*',
					element: <Navigate to={paths.admin_create_enquiry} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_manage_enquiry_orders, paths.admin),
			element: withSuspense(<AdminOrders />),
			title: strings('manage_enquiry_orders'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_manage_enquiry_order_status('active')} /> },
				{
					path: paths.admin_manage_enquiry_order_status(),
					element: withSuspense(<AdminOrdersByStatus isEnquiryOrders />),
				},
				{
					path: paths.admin_manage_enquiry_orders_active_invoice,
					element: withSuspense(<AdminOrdersActiveInvoice />),
				},
				{
					path: paths.admin_manage_enquiry_orders_active_trip,
					element: withSuspense(<AdminOrdersActiveTrip />),
				},
				{
					path: paths.admin_manage_enquiry_order_edit(),
					element: withSuspense(<AdminManageOrder />),
				},
				{
					path: paths.admin_manage_enquiry_orders_order_book,
					element: withSuspense(<AdminOrdersOrderBook />),
				},

				{
					path: '*',
					element: <Navigate to={paths.admin_manage_enquiry_order_status('active')} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_manage_auction_orders, paths.admin),
			element: withSuspense(<AdminAuctionOrders />),
			title: strings('manage_auction_orders'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_manage_auction_order_status('active')} /> },
				{
					path: paths.admin_manage_auction_order_status(),
					element: withSuspense(<AdminAuctionOrdersByStatus isAuction />),
				},
				{
					path: paths.admin_manage_auction_order_edit(),
					element: withSuspense(<AdminManageAuctionOrder />),
				},
				{
					path: '*',
					element: <Navigate to={paths.admin_manage_auction_order_status('active')} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_payment_summary, paths.admin),
			element: withSuspense(<AdminPaymentSummary />),
			title: strings('ledgers'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_ledger} /> },
				{
					path: paths.admin_ledger,
					element: withSuspense(<Ledgers />),
				},
				{
					path: paths.admin_sale_invoices,
					element: withSuspense(<SaleInvoices />),
				},
				{
					path: paths.admin_purchase_invoices,
					element: withSuspense(<PurchaseInvoices />),
				},
				{
					path: paths.admin_past_record,
					element: withSuspense(<PastRecords isAdmin />),
				},
				{
					path: paths.admin_due_payments,
					element: withSuspense(<DuePayments />),
				},
				{
					path: paths.admin_advance_to_supplier,
					element: withSuspense(<DuePayments />),
				},
				{
					path: paths.admin_collections,
					element: withSuspense(<Collections />),
				},
				{
					path: '*',
					element: <Navigate to={paths.admin_ledger} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_list_inventory, paths.admin),
			element: withSuspense(<Inventory selected={0} />),
			title: strings('supplier_by_inventory'),
		},
		{
			path: paths.routePath(paths.admin_add_inventory, paths.admin),
			element: withSuspense(<Inventory selected={1} />),
			title: strings('supplier_by_inventory'),
		},
		{
			path: paths.routePath(paths.admin_bid_auction, paths.admin),
			element: <AdminAuction />,
			children: [
				{ index: true, element: <Navigate to={paths.admin_create_auction} /> },
				{ path: paths.admin_create_auction, element: withSuspense(<AdminCreateAuction />), title: strings('create_auction') },
				{
					path: paths.admin_manage_auction,
					element: withSuspense(<AdminManageAuctions />),
					title: strings('manage_auctions'),
					children: [
						{ index: true, element: <Navigate to={paths.admin_manage_auction_all} /> },
						{ path: paths.admin_manage_auction_assign, element: withSuspense(<AdminAssignAuction status={auctionStatus.created} />) },
						{ path: paths.admin_manage_auction_all, element: withSuspense(<AdminAllAuctions />) },
						{ path: paths.admin_manage_auction_verify, element: withSuspense(<AdminVerifyAuction status={auctionStatus.unverified} />) },
						{ path: paths.admin_manage_auction_active, element: withSuspense(<AdminActiveAuctions status={auctionStatus.active} />) },
						{ path: paths.admin_manage_auction_my, element: withSuspense(<AdminMyAuctions />) },
						{ path: paths.admin_manage_auction_edit(), element: withSuspense(<AdminEditAuction />) },
						{ path: paths.admin_manage_auction_view(), element: withSuspense(<AdminViewAuction />) },
						{ path: paths.admin_manage_auction_manage(), element: withSuspense(<AdminManageAuction />) },
						{
							path: '*',
							element: <Navigate to={paths.admin_manage_auction_all} />,
						},
					],
				},
				{
					path: '*',
					element: <Navigate to={paths.admin} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_logistics, paths.admin),
			element: withSuspense(<AdminLogistics />),
			title: strings('logistics'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_logistics_all} /> },
				{ path: paths.admin_logistics_all, element: withSuspense(<LogisticsAll />) },
				{
					path: paths.admin_manage_logistics,
					element: withSuspense(<AdminManageLogistics />),
					children: [
						{ index: true, element: <Navigate to={paths.admin_manage_logistics_edit} /> },
						{
							path: paths.admin_manage_logistics_edit(),
							element: withSuspense(<EditLogistics />),
						},
						{
							path: paths.admin_manage_logistics_quotes,
							element: withSuspense(<LogisticsAllQuotes />),
						},
						{
							path: '*',
							element: <Navigate to={paths.admin_manage_logistics_edit} />,
						},
					],
				},
				{
					path: paths.admin_create_logistics,
					element: withSuspense(<AddLogistics />),
				},
				{
					path: paths.admin_logistics_order,
					element: withSuspense(<LogisticsOrder />),
				},
				{
					path: paths.admin_logistics_price,
					element: withSuspense(<LogisticsPrice />),
				},
				{
					path: paths.admin_manage_logistics_order_details(),
					element: withSuspense(<LogisticsOrderDetails />),
				},
				{
					path: '*',
					element: <Navigate to={paths.admin_logistics_all} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_cloud_factory, paths.admin),
			element: withSuspense(<AdminCloudFactory />),
			title: strings('cloud_factory'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_cloud_factories} /> },
				{ path: paths.admin_cloud_factories, element: withSuspense(<AdminListFactories />) },
				{ path: paths.admin_cloud_machines, element: withSuspense(<AdminListMachines />) },
				{ path: paths.admin_add_factory, element: withSuspense(<AdminCreateFactory />) },
				{ path: paths.admin_add_machine_template, element: withSuspense(<AdminCreateMachine />) },
				{ path: paths.admin_cloud_edit_machine(), element: withSuspense(<AdminEditMachine />) },
				{ path: paths.admin_cloud_edit_factory(), element: withSuspense(<AdminEditFactory />) },
				{ path: paths.admin_cloud_orders, element: withSuspense(<AdminListFactoryOrders />) },
				{ path: paths.admin_cloud_factory_create_order, element: withSuspense(<AdminCreateFactoryOrder />) },
				{ path: paths.admin_cloud_factory_manage_order(), element: withSuspense(<AdminManageCloudOrder />) },
				{
					path: '*',
					element: <Navigate to={paths.admin_cloud_factories} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_tools, paths.admin),
			element: <AdminTools />,
			title: strings('tools'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_tools_all} /> },
				{ path: paths.admin_tools_all, element: withSuspense(<AdminAllTools />) },
				{ path: paths.admin_tools_endorsement, element: withSuspense(<AdminEndorsement />) },
				{ path: paths.admin_tools_invoice_endorsement, element: withSuspense(<AdminInvoiceEndorsement />) },
				{ path: paths.admin_tools_logistics_calculator, element: withSuspense(<AdminLogisticCalculator />) },
				{ path: paths.admin_tools_distance_calculator, element: withSuspense(<AdminDistanceCalculator />) },
				{ path: paths.admin_tools_vernacularize, element: withSuspense(<AdminVernacularize />) },
				{ path: paths.admin_tools_ifc, element: withSuspense(<AdminToolsIFC />) },
				{ path: paths.admin_weight_calculator, element: withSuspense(<WeightCalculator />) },
				{ path: paths.admin_tools_data_correction, element: withSuspense(<DataCorrection />) },

				{
					path: '*',
					element: <Navigate to={paths.admin_tools} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_content, paths.admin),
			element: <AdminContent />,
			title: strings('content'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_content_all} /> },
				{ path: paths.admin_content_all, element: withSuspense(<AdminAllContent />) },
				{ path: paths.admin_content_banner, element: withSuspense(<AdminBanner />) },
				{ path: paths.admin_content_blog, element: withSuspense(<Blog />) },
				{ path: paths.admin_content_blog_create, element: withSuspense(<CreateBlog />) },
				{ path: paths.admin_content_blog_edit(), element: withSuspense(<CreateBlog />) },
				{ path: paths.admin_content_news, element: withSuspense(<News />) },
				{ path: paths.admin_content_glossary, element: withSuspense(<Glossary />) },
				{ path: paths.admin_content_glossary_create, element: withSuspense(<AddGlossary />) },
				{ path: paths.admin_content_glossary_edit(), element: withSuspense(<AddGlossary />) },
				{
					path: '*',
					element: <Navigate to={paths.admin_content} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_reports, paths.admin),
			element: <AdminReports />,
			title: strings('reports'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_reports_all} /> },
				{ path: paths.admin_reports_all, element: withSuspense(<AdminAllReports />) },
				{ path: paths.admin_view_report(), element: withSuspense(<ViewReport />) },
				{
					path: '*',
					element: <Navigate to={paths.admin_reports} />,
				},
			],
		},
		{
			path: paths.routePath(paths.admin_commodity_prices, paths.admin),
			element: withSuspense(<CommodityPrices />),
			title: strings('commodity', 'prices'),
		},
		{
			path: paths.routePath(paths.admin_bills, paths.admin),
			element: withSuspense(<AdminInvoice />),
			title: strings('bills'),
		},
		{
			path: paths.routePath(paths.admin_underwriting, paths.admin),
			element: <AdminUnderwriting />,
			title: strings('underwriting'),
			children: [
				{ index: true, element: <Navigate to={paths.admin_underwriting_all} /> },
				{
					path: paths.admin_underwriting_all,
					element: withSuspense(<AllUnderwriting />),
					children: [
						{ index: true, element: <Navigate to={paths.admin_underwriting_report} /> },
						{
							path: paths.admin_underwriting_report,
							element: withSuspense(<UnderwritingReport />),
						},
						{
							path: paths.admin_underwriting_request,
							element: withSuspense(<UnderwritingRequest />),
						},
						{
							path: '*',
							element: <Navigate to={paths.admin_underwriting_report} />,
						},
					],
				},
				{ path: paths.admin_underwriting_view, element: withSuspense(<ViewUnderwritingReport />), title: strings('view', 'underwriting') },
				{
					path: '*',
					element: <Navigate to={paths.admin_underwriting} />,
				},
			],
		},
		{
			path: paths.routePath(paths.mbDocuments, ''),
			element: withSuspense(<MBDocuments />),
		},
		{
			path: paths.routePath(paths.sale_admin_collections, paths.admin),
			element: withSuspense(<SaleCollections />),
			title: strings('collections'),
		},
		{
			path: '*',
			element: <Navigate to={paths.admin} />,
		},
	],
}

export default AdminRoutes
