import { convertDataFile } from '@utils'
import API from '../api'
import convertPagableData from '../convertPagableData'
import getUrl from './paths'

export const getBankDetails = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getBankDetails, { removeDefault: true })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllCreditReports = (page = 0, orderFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getCreditReports, { page, ...orderFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllCreditRequestApi = (page = 0, orderFilters = null) => 
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllCreditRequest, { page, ...orderFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})


export const getReportById = (pan, reportId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getReportById, { pan, reportId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAllReports = (pan) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllReports, { pan })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const restartBankStatementAnalysis = (pan, reportId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.restartBankStatementAnalysis, { pan, reportId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const fetchBSAExcelReport = (pan, reportId) =>
	new Promise((resolve, reject) => {
		API.get(
			getUrl.getBSAExcelReport,
			{ pan, reportId },
			{
				responseType: 'arraybuffer',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/vnd.ms-excel',
				},
			}
		)
			.then((response) => {
				convertDataFile(response.data, 'mb_bsa_' + reportId, 'xlsx')
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
