//always add 'admin' in key to distinguish it from client paths
const adminPaths = {
	admin: '/admin',
	admin_dashboard: '/admin/dashboard',
	admin_profile: '/admin/profile',
	admin_orgs_and_users: '/admin/orgs-and-users',
	admin_view_admins: '/admin/orgs-and-users/view-admins',
	admin_view_users: '/admin/orgs-and-users/view-users',
	admin_view_organisations: '/admin/orgs-and-users/organisations',
	admin_unregistered: '/admin/orgs-and-users/unregistered',
	admin_lead_organisations: '/admin/orgs-and-users/leads',
	admin_create_user: '/admin/orgs-and-users/create-user',
	admin_organisation: (orgId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId
	},
	admin_organisation_details: (orgId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId + '/details'
	},
	admin_organisation_users: (orgId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId + '/users'
	},
	admin_organisation_ledger: (orgId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId + '/ledger'
	},
	admin_organisation_leadlist: (orgId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId + '/leads'
	},
	admin_organisation_financials: (orgId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId + '/financials'
	},
	admin_lead_detail: (leadId = '') => {
		if (leadId === '') {
			leadId = ':leadId'
		}
		return '/admin/orgs-and-users/lead/' + leadId
	},
	admin_organisation_edit_user: (orgId = '', userId = '') => {
		if (orgId === '') {
			orgId = ':orgId'
		}
		if (userId === '') {
			userId = ':userId'
		}
		return '/admin/orgs-and-users/organisation/' + orgId + '/user/' + userId
	},
	admin_edit_user: (userId = '') => {
		if (userId === '') {
			userId = ':userId'
		}
		return '/admin/orgs-and-users/edit-user/' + userId
	},

	admin_catalog: '/admin/catalog',
	admin_catalog_categories: '/admin/catalog/categories',
	admin_catalog_create_category: '/admin/catalog/create-category',
	admin_catalog_products: '/admin/catalog/products',
	admin_catalog_create_product: '/admin/catalog/create-product',
	admin_catalog_brands: '/admin/catalog/brands',
	admin_catalog_create_brand: '/admin/catalog/create-brand',
	admin_catalog_commodity: '/admin/catalog/commodity',

	admin_catalog_edit_category: (categoryId = '') => {
		if (categoryId === '') {
			categoryId = ':categoryId'
		}
		return '/admin/catalog/edit-category/' + categoryId
	},

	admin_catalog_edit_product: (productId = '') => {
		if (productId === '') {
			productId = ':productId'
		}
		return '/admin/catalog/edit-product/' + productId
	},

	admin_enquiry: '/admin/enquiry',
	admin_create_enquiry: '/admin/enquiry/create-enquiry',
	admin_punch_order: '/admin/enquiry/punch-order',
	admin_punch_order_create: '/admin/enquiry/punch-order/create',
	admin_punch_order_view: (punchOrderId = '') => {
		if (punchOrderId === '') {
			punchOrderId = ':punchOrderId'
		}
		return '/admin/enquiry/punch-order/view/' + punchOrderId
	},

	admin_manage_enquiry: '/admin/enquiry/manage-enquiry',
	admin_manage_enquiry_assign: '/admin/enquiry/manage-enquiry/assign',
	admin_manage_enquiry_verify: '/admin/enquiry/manage-enquiry/verify',
	admin_manage_enquiry_all: '/admin/enquiry/manage-enquiry/all',
	admin_manage_enquiry_my: '/admin/enquiry/manage-enquiry/my',
	admin_manage_enquiry_active: '/admin/enquiry/manage-enquiry/active',
	admin_manage_enquiry_view: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/admin/enquiry/manage-enquiry/view/' + enquiryId
	},
	admin_manage_enquiry_edit: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/admin/enquiry/manage-enquiry/edit/' + enquiryId
	},
	admin_manage_enquiry_manage: (enquiryId = '') => {
		if (enquiryId === '') {
			enquiryId = ':enquiryId'
		}
		return '/admin/enquiry/manage-enquiry/manage/' + enquiryId
	},
	admin_manage_enquiry_orders: '/admin/order/manage-enquiry-orders',
	admin_manage_enquiry_orders_active_trip: '/admin/order/manage-enquiry-orders/active-trip',
	admin_manage_enquiry_order_status: (status = '') => {
		if (status === '') {
			status = ':orderStatus'
		}
		return '/admin/order/manage-enquiry-orders/' + status
	},
	admin_manage_enquiry_orders_active_invoice: '/admin/order/manage-enquiry-orders/active-invoice',
	admin_manage_enquiry_orders_order_book: '/admin/order/manage-enquiry-orders/order-book',

	admin_manage_enquiry_order_edit: (orderId = '') => {
		if (orderId === '') {
			orderId = ':orderId'
		}
		return '/admin/order/manage-enquiry-orders/edit/' + orderId
	},

	admin_payment_summary: '/admin/payment-summary',
	admin_ledger: '/admin/payment-summary/ledger',
	admin_sale_invoices: '/admin/payment-summary/sale-invoices',
	admin_purchase_invoices: '/admin/payment-summary/purchase-invoices',
	admin_past_record: '/admin/payment-summary/past-records',
	admin_due_payments: '/admin/payment-summary/due-payments',
	admin_advance_to_supplier: '/admin/payment-summary/advance-to-supplier',
	admin_collections: '/admin/payment-summary/collections',

	admin_list_inventory: '/admin/inventory',
	admin_add_inventory: '/admin/inventory/add',

	admin_bid_auction: '/admin/auction',
	admin_create_auction: '/admin/auction/create-auction',

	admin_manage_auction: '/admin/auction/manage-auction',
	admin_manage_auction_assign: '/admin/auction/manage-auction/assign',
	admin_manage_auction_verify: '/admin/auction/manage-auction/verify',
	admin_manage_auction_all: '/admin/auction/manage-auction/all',
	admin_manage_auction_my: '/admin/auction/manage-auction/my',
	admin_manage_auction_active: '/admin/auction/manage-auction/active',
	admin_manage_auction_view: (auctionId = '') => {
		if (auctionId === '') {
			auctionId = ':auctionId'
		}
		return '/admin/auction/manage-auction/view/' + auctionId
	},
	admin_manage_auction_edit: (auctionId = '') => {
		if (auctionId === '') {
			auctionId = ':auctionId'
		}
		return '/admin/auction/manage-auction/edit/' + auctionId
	},
	admin_manage_auction_manage: (auctionId = '') => {
		if (auctionId === '') {
			auctionId = ':auctionId'
		}
		return '/admin/auction/manage-auction/manage/' + auctionId
	},

	admin_manage_auction_orders: '/admin/auction-order/manage-auction-orders',
	admin_manage_auction_order_status: (status = '') => {
		if (status === '') {
			status = ':orderStatus'
		}
		return '/admin/auction-order/manage-auction-orders/' + status
	},
	admin_manage_auction_order_edit: (orderId = '') => {
		if (orderId === '') {
			orderId = ':orderId'
		}
		return '/admin/auction-order/manage-auction-orders/edit/' + orderId
	},

	admin_logistics: '/admin/logistics',
	admin_create_logistics: '/admin/logistics/create-enquiry',
	admin_logistics_all: '/admin/logistics/all',
	admin_logistics_order: '/admin/logistics/order',
	admin_manage_logistics: '/admin/logistics/manage-logistics',
	admin_logistics_price: '/admin/logistics/price',
	admin_manage_logistics_quotes: '/admin/logistics/manage-logistics/quotes',
	admin_manage_logistics_view: (logisticsId = '') => {
		if (logisticsId === '') {
			logisticsId = ':logisticsId'
		}
		return '/admin/logistics/manage-logistics/view/' + logisticsId
	},
	admin_manage_logistics_edit: (logisticsId = '') => {
		if (logisticsId === '') {
			logisticsId = ':logisticsId'
		}
		return '/admin/logistics/manage-logistics/edit/' + logisticsId
	},
	admin_manage_logistics_order_details: (logisticsOrderId = '') => {
		if (logisticsOrderId === '') {
			logisticsOrderId = ':logisticsOrderId'
		}
		return '/admin/logistics/manage-logistics/order/details/' + logisticsOrderId
	},
	admin_cloud_factory: '/admin/cloud-factory',
	admin_cloud_factories: '/admin/cloud-factory/factories',
	admin_cloud_machines: '/admin/cloud-factory/machines',
	admin_cloud_orders: '/admin/cloud-factory/orders',
	admin_add_factory: '/admin/cloud-factory/factories/add',
	admin_add_machine_template: '/admin/cloud-factory/machines/add',
	admin_cloud_edit_machine: (machineId = '') => {
		if (machineId === '') {
			machineId = ':machineId'
		}
		return '/admin/cloud-factory/machines/edit/' + machineId
	},
	admin_cloud_edit_factory: (factoryId = '') => {
		if (factoryId === '') {
			factoryId = ':factoryId'
		}
		return '/admin/cloud-factory/factories/edit/' + factoryId
	},
	admin_cloud_factory_create_order: '/admin/cloud-factory/order/create',
	admin_cloud_factory_manage_order: (orderId = '') => {
		if (orderId === '') {
			orderId = ':orderId'
		}
		return '/admin/cloud-factory/order/manage/' + orderId
	},

	admin_tools: '/admin/tools',
	admin_tools_all: '/admin/tools/all',
	admin_tools_endorsement: '/admin/tools/endorsement',
	admin_tools_invoice_endorsement: '/admin/tools/invoice-endorsement',
	admin_tools_logistics_calculator: '/admin/tools/logistics-calculator',
	admin_tools_distance_calculator: '/admin/tools/distance-calculator',
	admin_tools_vernacularize: '/admin/tools/vernacularize',
	admin_tools_ifc: '/admin/tools/ifc',
	admin_tools_banner: '/admin/tools/banner',
	admin_weight_calculator: '/admin/tools/weight-calculator',
	admin_tools_data_correction: '/admin/tools/data-correction',

	admin_content: '/admin/content',
	admin_content_all: '/admin/content/all',
	admin_content_banner: '/admin/content/banner',
	admin_content_blog: '/admin/content/blog',
	admin_content_blog_create: '/admin/content/blog/create',
	admin_content_blog_edit: (contentId = '') => {
		if (contentId === '') {
			contentId = ':contentId'
		}
		return '/admin/content/blog/edit/' + contentId
	},
	admin_content_news: '/admin/content/news',
	// admin_content_news_create: '/admin/content/news/create',
	// admin_content_news_edit: (newsId = '') => {
	// 	if (newsId === '') {
	// 		newsId = ':newsId'
	// 	}
	// 	return '/admin/content/news/edit/' + newsId
	// },
	admin_content_glossary: '/admin/content/glossary',
	admin_content_glossary_create: '/admin/content/glossary/create',
	admin_content_glossary_edit: (glossarySlug = '') => {
		if (glossarySlug === '') {
			glossarySlug = ":glossarySlug"
		}
		return '/admin/content/glossary/edit/' + glossarySlug
	},

	admin_reports: '/admin/reports',
	admin_reports_all: '/admin/reports/all',
	admin_view_report: (reportType = '') => {
		if (reportType === '') {
			reportType = ':reportType'
		}
		return '/admin/reports/all/' + reportType
	},

	admin_commodity_prices: '/admin/commodity-prices',
	admin_bills: '/admin/bills',

	admin_underwriting: '/admin/underwriting',
	admin_underwriting_all: '/admin/underwriting/all',
	admin_underwriting_report: '/admin/underwriting/all/report',
	admin_underwriting_request: '/admin/underwriting/all/request',
	admin_underwriting_view: '/admin/underwriting/view',
	sale_admin_collections: '/admin/collections',
}

export default adminPaths
