import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { DrawerBodyWrapper, DrawerFooterWrapper, Input, RadioButton, Select, TOAST_TYPE, UploadButton, Banner as BannerText } from '@base'
import { ActionButton, FileName, FormHeading, FormItemWrapper, FormWrapper, Label, RadioButtonWrapper } from './styles'
import { strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { createBannerApi, editBannerApi } from '@data'
import { showLoader, showToast } from '@data/state/action'

const BANNER_STATUS = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
}

const typeData = [{ name: 'VIDEO' }, { name: 'IMAGE' }, { name: 'APP' }]
const eventData = [
	{ name: 'ENQUIRY_DETAIL' },
	{ name: 'ENQUIRY_QUOTE' },
	{ name: 'ORDER_DETAIL' },
	{ name: 'ORDER_INVOICE' },
	{ name: 'AUCTION_DETAIL' },
	{ name: 'AUCTION_BID' },
	{ name: 'ORGANISATION_KYC_REQUESTED' },
	{ name: 'HOME_DASHBOARD' },
	{ name: 'ENQUIRY_ADD_QUOTE' },
	{ name: 'AUCTION_ADD_BID' },
]
const platformData = [{ name: 'SCRAP' }, { name: 'MARKETPLACE' }, { name: 'FABRICATOR' }]
const roleData = [{ status: 'BUYER' }, { status: 'SUPPLIER' }]

const deriveStateFromData = (data, editable = true) => {
	const _state = {
		type: {
			label: strings('type'),
			value: { name: null },
			disabled: false,
			placeholder: strings(['select', 'service', 'type']),
			data: typeData,
			displayKey: 'name',
			required: true,
			dirty: false,
		},
		file: {
			label: strings('type'),
			value: '',
			required: false,
			dirty: false,
		},
		event: {
			label: strings('select', 'event'),
			value: { name: null },
			disabled: false,
			placeholder: strings(['select', 'event']),
			data: eventData,
			displayKey: 'name',
			required: false,
			dirty: false,
		},
		media_url: {
			label: strings('media_url'),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings('banner_ratio'),
			dirty: false,
		},
		event_id: {
			label: strings(['invoice', '/', 'quote', 'id']),
			value: '',
			disabled: false,
			placeholder: strings(['enter', 'invoice', '/', 'quote', 'id']),
			required: false,
			dirty: false,
		},
		source_id: {
			label: strings(['enquiry', '/', 'auction', '/', 'order', 'id']),
			value: '',
			disabled: false,
			required: false,
			placeholder: strings(['enter', 'enquiry', '/', 'auction', '/', 'order', 'id']),
			dirty: false,
		},
		platform: {
			label: strings('platform'),
			value: { name: null },
			disabled: false,
			placeholder: strings(['select', 'platform', 'type']),
			data: platformData,
			displayKey: 'name',
			required: true,
			dirty: false,
		},
		role: {
			label: strings('role'),
			value: { status: null },
			disabled: false,
			placeholder: strings(['select', 'role', 'type']),
			data: roleData,
			displayKey: 'status',
			required: true,
			dirty: false,
		},
		priority: {
			label: strings('priority'),
			value: 1,
			disabled: false,
			required: false,
			placeholder: strings(['enter', 'priority']),
			dirty: false,
		},
		active: {
			label: strings('active'),
			value: BANNER_STATUS.ACTIVE,
			disabled: false,
			dirty: false,
		},
	}
	if (data) {
		_state.type.value = { name: data?.type || null }
		_state.type.disabled = true
		_state.type.dirty = true

		_state.event.value = { name: data?.page || null }
		_state.event.disabled = true
		_state.event.dirty = true

		_state.media_url.value = data?.media_url
		_state.media_url.disabled = true
		_state.media_url.dirty = true

		_state.file.value = data?.media_url
		_state.file.disabled = true
		_state.file.dirty = true

		_state.event_id.value = data?.event_id
		_state.event_id.disabled = true
		_state.event_id.dirty = true

		_state.source_id.value = data?.source_id
		_state.source_id.disabled = true
		_state.source_id.dirty = true

		_state.platform.value = { name: data?.platform || null }
		_state.platform.disabled = true
		_state.platform.dirty = true

		_state.role.value = { status: data?.role || null }
		_state.role.disabled = true
		_state.role.dirty = true

		_state.active.value = data?.active ? BANNER_STATUS.ACTIVE : BANNER_STATUS.INACTIVE
		_state.active.disabled = true
		_state.active.dirty = true

		_state.priority.value = data?.priority
		_state.priority.disabled = true
		_state.priority.dirty = true
	}
	return _state
}

const AddBanner = ({ bannerData, bannerById, editable = true, update }) => {
	const [formState, setFormState] = useState(deriveStateFromData())
	const [fileError, setFileError] = useState('')
	const dispatch = useDispatch()

	useEffect(() => {
		setFormState((_) => deriveStateFromData(bannerData, !!editable))
	}, [bannerData, editable])

	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
		if (key === 'type') {
			updateState('event', { value: '', dirty: false })
			updateState('media_url', { value: '', dirty: false })
			updateState('event_id', { value: '', dirty: false })
			updateState('source_id', { value: '', dirty: false })
			updateState('file', { value: '', dirty: false })
			setFileError('')
		}
	}

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const onRadioChange = (key, value) => updateState(key, { value, dirty: true })

	const calculateAspectRatio = (width, height) => {
		const gcd = (a, b) => {
			if (b === 0) {
				return a
			}
			return gcd(b, a % b)
		}
		const ratioGcd = gcd(width, height)
		// Calculate the aspect ratio
		return `${width / ratioGcd}:${height / ratioGcd}`
	}

	const uploadHandler = (file) => {
		if (!file) return

		const reader = new FileReader()
		reader.readAsDataURL(file)

		reader.onload = (e) => {
			const img = new Image()
			img.onload = () => {
				// 1920*1080 Outputs 16:9, 400*172 Outputs 100:43,
				setFileError('')
				if (calculateAspectRatio(img.width, img.height) === '100:43') {
					onRadioChange('file', file)
				} else {
					setFileError(`Invalid aspected ratio size - ${img.width}x${img.heigth}`)
					onChange('file')('')
				}
			}
			img.src = e.target.result
		}
	}

	const addNewItemHandler = () => {
		dispatch(showLoader(true))
		createBannerApi(
			formState?.type?.value?.name,
			formState?.event?.value?.name,
			formState?.media_url?.value,
			formState?.event_id?.value,
			formState?.source_id?.value,
			formState?.platform?.value?.name,
			formState?.role?.value?.status,
			formState?.active?.value === BANNER_STATUS?.ACTIVE ? true : false,
			formState?.file?.value,
			formState?.priority?.value
		)
			.then((res) => {
				update()
				dispatch(showToast(true, strings('msg_banner_created'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				dispatch(showToast(true, err.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const updateHandler = () => {
		dispatch(showLoader(true))
		editBannerApi(bannerById, formState?.active?.value === BANNER_STATUS?.ACTIVE ? true : false, formState?.priority?.value)
			.then((res) => {
				update()
				dispatch(showToast(true, strings('msg_banner_editted'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				dispatch(showToast(true, err.response.data.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const cancelEditHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const checkDisabled = () => {
		if (formState?.priority.value > 5 || formState?.priority.value < 1) {
			return true
		}
		if (formState?.type.value?.name === 'VIDEO') {
			return !formState?.media_url?.dirty
		} else if (formState?.type.value?.name === 'IMAGE') {
			return !formState?.file?.dirty
		} else if (formState?.type.value?.name === 'APP') {
			return !formState?.file?.dirty || !formState?.event?.dirty || !formState?.event_id?.dirty || !formState?.source_id?.dirty
		} else if (formState?.type.value?.name === null) return true
	}

	const _disabled = formState?.type?.dirty && formState?.platform?.dirty && formState?.role?.dirty

	const renderButtons = () => {
		if (!bannerById && editable) {
			return (
				<ActionButton small type='primary' disabled={!_disabled || checkDisabled()} onClick={addNewItemHandler}>
					{bannerById ? strings('edit', 'banner') : strings('add', 'banner')}
				</ActionButton>
			)
		} else if (bannerById && editable) {
			return (
				<>
					<ActionButton small disabled={_disabled && checkDisabled()} type='primary' onClick={updateHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
				</>
			)
		}
	}
	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<FormItemWrapper>
						<Select
							primaryKey={formState?.type?.displayKey}
							displayKey={formState?.type?.displayKey}
							data={formState?.type?.data}
							label={formState?.type?.label}
							disabled={formState?.type?.disabled}
							value={formState?.type?.value}
							placeholder={formState?.type?.placeholder}
							onChange={onChange('type')}
							required={formState?.type?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Select
							primaryKey={formState?.platform?.displayKey}
							displayKey={formState?.platform?.displayKey}
							data={formState?.platform?.data}
							label={formState?.platform?.label}
							disabled={formState?.platform?.disabled}
							value={formState?.platform?.value}
							placeholder={formState?.platform?.placeholder}
							onChange={onChange('platform')}
							required={formState?.platform?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Select
							primaryKey={formState?.role?.displayKey}
							displayKey={formState?.role?.displayKey}
							data={formState?.role?.data}
							label={formState?.role?.label}
							disabled={formState?.role?.disabled}
							value={formState?.role?.value}
							placeholder={formState?.role?.placeholder}
							onChange={onChange('role')}
							required={formState?.role?.required}
						/>
					</FormItemWrapper>

					<FormItemWrapper isTwo>
						<FormItemWrapper>
							<Input
								type='text'
								label={formState?.media_url?.label}
								disabled={formState?.type.disabled || ['IMAGE', 'APP']?.includes(formState?.type.value?.name)}
								value={formState?.media_url?.value}
								placeholder={formState?.media_url?.placeholder}
								onChange={onChange('media_url')}
								required={formState?.media_url?.required}
								pattern='https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}'
								errorMap={{
									patternMismatch: strings('enter_valid_url_link'),
								}}
							/>
						</FormItemWrapper>
						<UploadButton
							disabled={formState?.type.disabled || formState?.type?.value?.name === 'VIDEO'}
							label={strings('upload', 'file')}
							small
							uppercase
							onChange={uploadHandler}
						/>
					</FormItemWrapper>
					{!bannerById && <FileName>{formState?.file?.value?.name}</FileName>}
					{fileError && (
						<FormWrapper>
							<BannerText warning={true} extendInMobile text={fileError} />
						</FormWrapper>
					)}

					<FormItemWrapper>
						<Select
							primaryKey={formState?.event?.displayKey}
							displayKey={formState?.event?.displayKey}
							data={formState?.event?.data}
							label={formState?.event?.label}
							disabled={formState?.type.disabled || ['IMAGE', 'VIDEO']?.includes(formState?.type.value?.name)}
							value={formState?.event?.value}
							placeholder={formState?.event?.placeholder}
							onChange={onChange('event')}
							required={formState?.event?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper>
						<Input
							type='text'
							label={formState?.source_id?.label}
							disabled={formState?.type.disabled || ['IMAGE', 'VIDEO']?.includes(formState?.type.value?.name)}
							value={formState?.source_id?.value}
							placeholder={formState?.source_id?.placeholder}
							onChange={onChange('source_id')}
							required={formState?.source_id?.required}
						/>
					</FormItemWrapper>
					<FormItemWrapper isTwo>
						<Input
							type='text'
							label={formState?.event_id?.label}
							disabled={formState?.type.disabled || ['IMAGE', 'VIDEO']?.includes(formState?.type.value?.name)}
							value={formState?.event_id?.value}
							placeholder={formState?.event_id?.placeholder}
							onChange={onChange('event_id')}
							required={formState?.event_id?.required}
						/>

						<Input
							type='number'
							label={formState?.priority?.label}
							value={formState?.priority?.value}
							placeholder={formState?.priority?.placeholder}
							onChange={onChange('priority')}
							step='1'
							max={5}
							min={1}
							required={formState?.priority?.required}
						/>
					</FormItemWrapper>

					<FormHeading>{strings('status')}</FormHeading>
					<FormItemWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='true-radio'
								size='1.125'
								checked={formState?.active?.value === BANNER_STATUS.ACTIVE}
								value={BANNER_STATUS.ACTIVE}
								onChange={(e) => onRadioChange('active', e.target.value)}
							/>
							<Label htmlFor='true-radio'>{strings('active')}</Label>
						</RadioButtonWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='false-radio'
								size='1.125'
								checked={formState?.active?.value === BANNER_STATUS.INACTIVE}
								value={BANNER_STATUS.INACTIVE}
								onChange={(e) => onRadioChange('active', e.target.value)}
							/>
							<Label htmlFor='false-radio'>{strings('inactive')}</Label>
						</RadioButtonWrapper>
					</FormItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>{renderButtons()}</DrawerFooterWrapper>
		</>
	)
}

export default AddBanner
