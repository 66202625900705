import API from '../api'
import putUrl from './paths'

export const submitEnquiryQuote = (loggedIn, enquiryId, submittedQuote, newAddressAdded = false) =>
	new Promise((resolve, reject) => {
		submittedQuote.created_by = loggedIn?.user_id
		submittedQuote.created_at = new Date().getTime()
		API.put(putUrl.submitEnquiryQuote, submittedQuote, { enquiryId, newAddressAdded })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const editEnquiryQuote = (user, enquiryId, submittedQuote) =>
	new Promise((resolve, reject) => {
		submittedQuote.updated_by = user?.user_id
		API.put(putUrl.editEnquiryQuote, submittedQuote, { enquiryId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const submitBuyerQuote = (enquiryId, submittedQuote, finalQuoteSubmitted = false) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.submitBuyerQuote, submittedQuote, { enquiryId, finalQuoteSubmitted })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const editEnquiry = ({ enquiry, user, enquiryClosed = false }) =>
	new Promise((resolve, reject) => {
		enquiry.updated_by = user?.user_id
		enquiry.updated_at = new Date().getTime()
		API.put(putUrl.editEnquiry, enquiry, { enquiryClosed })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const submitScrapSupplierQuote = (enquiryIdList, submittedQuote, newAddressAdded = false) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.submitScrapSupplierQuote, submittedQuote, {
			enquiryIdList: enquiryIdList.join(','),
			newAddressAdded,
		})
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})
